<template>
  <div class="page page-use-code">
    <ValidationObserver ref="form" tag="form" class="form app-form" @submit.prevent="onSubmit()">
      <b-form-group>
        <b-form-input v-model="account.username" :readonly="true" autocomplete="off"/>
      </b-form-group>
      <b-form-group v-if="!this.idGame || this.idGame < 2">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-select v-model="formData.idGame" :options="games" :disabled="loading" value-field="id" text-field="name"/>
          <div class="msg-validate-error">{{ errors[0] }}</div>
        </ValidationProvider>
      </b-form-group>
      <b-form-group>
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-select v-model="formData.idServer" :options="servers" value-field="id" text-field="name" :disabled="loading || formData.idGame < 1"/>
          <div class="msg-validate-error">{{ errors[0] }}</div>
        </ValidationProvider>
      </b-form-group>
      <b-form-group>
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-select v-model="formData.idRole" :options="roles" value-field="id" text-field="name" :disabled="loading || formData.idServer < 1"/>
          <div class="msg-validate-error">{{ errors[0] }}</div>
        </ValidationProvider>
      </b-form-group>
      <b-form-group>
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-select v-model="formData.idEvent" :options="events"  value-field="id" text-field="name" :disabled="loading || event != null || formData.idRole < 1"/>
          <div class="msg-validate-error">{{ errors[0] }}</div>
        </ValidationProvider>
      </b-form-group>
      <b-form-group>
        <ValidationProvider :rules="formData.idEvent < 1 ? 'required' : null" v-slot="{ errors }">
          <b-input v-model="formData.code" :disabled="loading || formData.idRole < 1 || ((formData.idEvent > 0 || event != null) && !showCode)" :placeholder="$t('Enter a giftcode')"/>
          <div class="msg-validate-error">{{ errors[0] }}</div>
        </ValidationProvider>
      </b-form-group>
      <b-form-group>
        <b-button type="submit" variant="primary" block size="lg" class="font-weight-bold" :disabled="loading">
          {{ $t("Use a giftcode") }}
        </b-button>
      </b-form-group>
      <b-form-group class="pt-2">
        <div class="font-weight-bold">
          1. An account can only use the code once, if the account has many characters, please choose the right character you need to use.
        </div>
        <div>
          2. The gift will be sent to the mail in the game when the Giftcode is successfully entered.
        </div>
        <div>
          3. Note the possible causes that can lead to the unsuccessful Giftcode entry such as: Entering missing characters, mistakenly 2 nearly identical characters, expired Giftcode...
        </div>
        <div>
          4. For events with their own code, you need to participate in all the requirements of the event and receive the code from GM via Fanpage or Discord channels.
        </div>
        <div class="font-weight-bold text-danger">
          5. Giftcode is distributed exclusively from the HVN game portal and is completely free, the objects that entice you to buy the code or read the account to receive the code are scams.
        </div>
      </b-form-group>
    </ValidationObserver>
    <LoadingModal :show="loading"/>
  </div>
</template>

<script>
import gameService from "@/services/gameService";
import serverGameService from "@/services/serverGameService";
import roleService from "@/services/roleService";
import giftcodeService from "@/services/giftcodeService";
import dialogHelper from "@/helpers/dialogHelper";
export default {
  name: "UseCodePanel",
  props: ["idGame"],
  data() {
    return {
      loading: false,
      showCode: false,
      event: null,
      account: {
        username: ""
      },
      games: [],
      servers: [{
        id: 0,
        name: this.$t("Select a server")
      }],
      roles: [{
        id: 0,
        name: this.$t("Select a role")
      }],
      events: [{
        id: 0,
        name: this.$t("Select a not used event")
      }],
      formData: {
        idGame: 0,
        idServer: 0,
        idRole: 0,
        idEvent: 0,
        code: ""
      }
    }
  },
  created() {
    this.account = this.$store.getters.account;
    const query = this.$route.query;
    const idEvent = query.idEvent;
    const nameEvent = query.nameEvent;
    if (idEvent && idEvent > 0 && nameEvent && nameEvent.length) {
      if (query.showCode)
        this.showCode = true;
      this.event = {
        id: idEvent,
        name: nameEvent,
        isMultiple: this.showCode
      }
      this.events = [this.event];
      this.formData.idEvent = this.event.id;
    }
    this.loadGames();
  },
  methods: {
    async loadGames() {
      if (this.idGame && this.idGame > 1)
        this.formData.idGame = this.idGame;
      let games = this.$store.getters.games;
      if (!games) {
        this.loading = true;
        const response = await gameService.getAll();
        this.loading = false;
        if (!response)
        {
          await dialogHelper.alert("Connect to server failed. Please check your internet connection");
          await this.$router.push({name: "GiftCodeIndex"});
          return;
        }
        if (response.error) {
          await dialogHelper.alert(response.message);
          await this.$router.push({name: "GiftCodeIndex"});
          return;
        }
        games = response.data;
        await this.$store.dispatch("setGames", games);
      }
      const listGames = [{
        id: 0,
        name: this.$t("Select a game")
      }];
      this.games = [...listGames, ...games];
    },
    async onSubmit() {
      const valid = await this.$refs.form.validate();
      if (!valid)
        return;
      this.loading = true;
      await this.$recaptchaLoaded();
      const response = await giftcodeService.useGiftCode(this.formData, await this.$recaptcha('useGiftCode'))
      this.loading = false;
      if (!response)
      {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }
      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }
      await this.$store.dispatch("setGiftCodeSuccess", response.data);
      await this.$router.push({name: "UseGiftCodeSuccess"})
    }
  },
  watch: {
    "formData.idGame": async function (newValue) {
      let servers = [{
        id: 0,
        name: this.$t("Select a server")
      }];
      if (newValue > 0) {
        this.loading = true;
        const response = await serverGameService.getAllByGame(newValue);
        this.loading = false;
        if (!response)
          await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        else if (response.error)
          await dialogHelper.alert(response.message);
        else
          servers = [...servers, ...response.data];
      }
      this.servers = servers;
      this.formData.idServer = 0;
    },
    "formData.idServer": async function (newValue) {
      let roles = [{
        id: 0,
        name: this.$t("Select a role")
      }];
      if (newValue > 0) {
        this.loading = true;
        const response = await roleService.getRolesByServer(newValue);
        this.loading = false;
        if (!response)
          await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        else if (response.error)
          await dialogHelper.alert(response.message);
        else
          roles = [...roles, ...response.data];
      }
      this.roles = roles;
      this.formData.idRole = 0;
    },
    "formData.idRole": async function (newValue) {
      if (this.event)
        return;
      let events = [{
        id: 0,
        name: this.$t("Select a not used event")
      }];
      if (newValue > 0) {
        this.loading = true;
        const response = await giftcodeService.getNotUseCodes(this.formData);
        this.loading = false;
        if (!response)
          await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        else if (response.error)
          await dialogHelper.alert(response.message);
        else
          events = [...events, ...response.data];
      }
      this.events = events;
      this.formData.idEvent = 0;
    },
    "formData.idEvent": function (newValue) {
      const event = this.events.find(x => x.id === newValue);
      this.showCode = event && event.isMultiple;
    }
  }
}
</script>

<style scoped>
</style>
