<template>
  <div>
    <UseCodePanel v-if="game" :id-game="game.id"/>
    <NotFoundPanel v-else />
  </div>
</template>

<script>
import UseCodePanel from "@/components/panels/giftcode/UseCodePanel";

export default {
  name: "UseCode",
  components: {UseCodePanel},
  data() {
    return {
      game: null
    }
  },
  created() {
    this.game = this.$store.getters.currentGame;
  }
}
</script>

<style scoped>

</style>