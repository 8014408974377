var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-use-code"},[_c('ValidationObserver',{ref:"form",staticClass:"form app-form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('b-form-group',[_c('b-form-input',{attrs:{"readonly":true,"autocomplete":"off"},model:{value:(_vm.account.username),callback:function ($$v) {_vm.$set(_vm.account, "username", $$v)},expression:"account.username"}})],1),(!this.idGame || this.idGame < 2)?_c('b-form-group',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"options":_vm.games,"disabled":_vm.loading,"value-field":"id","text-field":"name"},model:{value:(_vm.formData.idGame),callback:function ($$v) {_vm.$set(_vm.formData, "idGame", $$v)},expression:"formData.idGame"}}),_c('div',{staticClass:"msg-validate-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4154931858)})],1):_vm._e(),_c('b-form-group',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"options":_vm.servers,"value-field":"id","text-field":"name","disabled":_vm.loading || _vm.formData.idGame < 1},model:{value:(_vm.formData.idServer),callback:function ($$v) {_vm.$set(_vm.formData, "idServer", $$v)},expression:"formData.idServer"}}),_c('div',{staticClass:"msg-validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"options":_vm.roles,"value-field":"id","text-field":"name","disabled":_vm.loading || _vm.formData.idServer < 1},model:{value:(_vm.formData.idRole),callback:function ($$v) {_vm.$set(_vm.formData, "idRole", $$v)},expression:"formData.idRole"}}),_c('div',{staticClass:"msg-validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"options":_vm.events,"value-field":"id","text-field":"name","disabled":_vm.loading || _vm.event != null || _vm.formData.idRole < 1},model:{value:(_vm.formData.idEvent),callback:function ($$v) {_vm.$set(_vm.formData, "idEvent", $$v)},expression:"formData.idEvent"}}),_c('div',{staticClass:"msg-validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('ValidationProvider',{attrs:{"rules":_vm.formData.idEvent < 1 ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"disabled":_vm.loading || _vm.formData.idRole < 1 || ((_vm.formData.idEvent > 0 || _vm.event != null) && !_vm.showCode),"placeholder":_vm.$t('Enter a giftcode')},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}),_c('div',{staticClass:"msg-validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-button',{staticClass:"font-weight-bold",attrs:{"type":"submit","variant":"primary","block":"","size":"lg","disabled":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t("Use a giftcode"))+" ")])],1),_c('b-form-group',{staticClass:"pt-2"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" 1. An account can only use the code once, if the account has many characters, please choose the right character you need to use. ")]),_c('div',[_vm._v(" 2. The gift will be sent to the mail in the game when the Giftcode is successfully entered. ")]),_c('div',[_vm._v(" 3. Note the possible causes that can lead to the unsuccessful Giftcode entry such as: Entering missing characters, mistakenly 2 nearly identical characters, expired Giftcode... ")]),_c('div',[_vm._v(" 4. For events with their own code, you need to participate in all the requirements of the event and receive the code from GM via Fanpage or Discord channels. ")]),_c('div',{staticClass:"font-weight-bold text-danger"},[_vm._v(" 5. Giftcode is distributed exclusively from the HVN game portal and is completely free, the objects that entice you to buy the code or read the account to receive the code are scams. ")])])],1),_c('LoadingModal',{attrs:{"show":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }